const config = {
  loading: false,
  advertisingTypeList: [],
  columnTypeList: [],
  circleList: [],
  imgKey: ['i_four_img', 'android_i_img', 'i_ten_img', 'i_ten_r_img'],
  videoKey: ['i_four_video', 'android_i_video', 'i_ten_video', 'i_ten_r_video'],
  sliderList: Object.freeze([
    {
      image: require('@/assets/images/operate/operation_spread_launch_gray.png'),
      name: '开屏广告',
      path: 'openScreen',
      place: 'first_page',
    },
    // {
    //   image: require('@/assets/images/operate/operation_spread_list.png'),
    //   name: '资讯列表广告',
    //   path: 'informationList',
    //   place: 'info_content_all',
    // },
    {
      image: require('@/assets/images/operate/operation_spread_index_floating_gray.png'),
      name: '首页浮动广告',
      path: 'homeFloat',
      place: 'index_floating',
    },
    {
      image: require('@/assets/images/operate/operation_spread_slider_gray.png'),
      name: '文章详情幻灯',
      path: 'articleDetailsSlide',
      place: 'portal_bottom_all',
    },
    {
      image: require('@/assets/images/operate/operation_spread_slider_gray.png'),
      name: '主题详情顶部广告（发布者下方）',
      path: 'detailsTop',
      place: 'content_top_user_behind',
    },
  ]),
  value: '0',
  phoneConfigList: Object.freeze([
    {
      id: 'ac_ia',
      label: '苹果审核版显示',
    },
    {
      id: 'ac_in',
      label: '苹果普通版显示',
    },
    {
      id: 'ac_aa',
      label: '安卓审核版显示',
    },
    {
      id: 'ac_an',
      label: '安卓普通版显示',
    },
  ]),
  // 是否
  whetherList1: Object.freeze([
    {
      id: 1,
      label: '是',
    },
    {
      id: 0,
      label: '否',
    },
  ]),
  // 是否
  whetherList2: Object.freeze([
    {
      id: 1,
      label: '是',
    },
    {
      id: -1,
      label: '否',
    },
  ]),
  // 是否
  typeList: Object.freeze([
    {
      id: 1,
      label: '图片',
    },
    {
      id: 2,
      label: '动图',
    },
    {
      id: 3,
      label: '视频',
    },
  ]),
  // 视频上传类型
  videoTypeList: Object.freeze([
    {
      id: 1,
      label: '视频路径',
    },
    {
      id: 2,
      label: '本地上传',
    },
  ]),
  // 样式选择
  styleSelectionList: Object.freeze([
    {
      id: 1,
      label: '非全屏显示',
    },
    {
      id: 2,
      label: '全屏显示',
    },
  ]),
}

export default config
